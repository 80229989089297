<template>
  <div v-if="!loaded && learningPaths.length">
    <div class="row mt-4 mb-4">
      <div class="col-md-9">
        <h2 class="font-weight-normal text-left">
          Learning Paths
        </h2>
      </div>
      <div class="col-md-3 text-right pt-md-4">
        <router-link :to="{ name: 'paths' }" class="text-right">
          View all Paths
        </router-link>
      </div>
    </div>

    <div class="row card-grid">
      <div v-for="lpath in learningPaths" :key="lpath.id" class="col-lg-4 col-md-6 col-sm-12 card-grid-col mb-3 mt-3">
        <router-link :to="{ name: 'path', params: { pathslug: lpath.slug } }" class="card heavy-shadow">
          <div class="w-100">
            <b-img-lazy class="card-img" :src="SPENV.S3_URL + '/images/paths-thumbs/path-' + lpath.path_id + '.jpg'" alt="Learning Track thumbnail"
                        onerror="this.onerror=null;this.src='/static/images/courses/placeholder-transparent.png'" />
            <div class="card-img-overlay-low text-white">
              <h4 class="px-2 card-low-banner rounded-bottom">
                {{ lpath.display_title }}
              </h4>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'LearningPathCards',
  props: {
    pathLimit: {
      type: Number,
      default: 3
    },
  },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    learningPaths() {
      // Sort by progress and path_id
      let allPaths = _.orderBy(this.$store.getters['paths/getPathList'], ['progress', 'path_id'], 'desc');
      return allPaths.slice(0, this.pathLimit );
    },
    learningPathsLoaded() {
      return this.$store.getters['paths/getPathsLoaded'];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init: function() {
      this.loading = true;
      if (!this.learningPathsLoaded) {
        let pathlist = this.$store.dispatch('paths/loadPathList');
        let pathProgress = this.$store.dispatch('paths/loadPathListProgress');
        Promise.all([pathlist, pathProgress]).then(
          (response) => {
            this.$store.commit('paths/setPathsLoaded', true);
            this.$store.commit('paths/setpathList', response);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    }
  }
};
</script>
