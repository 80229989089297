<template>
  <div id="library">
    <div class="jumbotron jumbotron-fluid texture texture-green sub-header" :style="{backgroundImage: `linear-gradient(180deg, rgba(65, 65, 65, 0.9) 0%, rgba(65, 65, 65, 0.9) 100%), url('${SPENV.S3_URL}/images/software-jumbotrons/library.jpg')`}">
      <div class="container">
        <h1 class="display-4">
          SolidProfessor Library
        </h1>
        <p class="lead">
          With more than 5,000 interactive lessons, the SolidProfessor Library
          has the training courses engineers need. Learn from continuously
          updated, expert-led tutorials. Practice your skills with hands-on
          exercises. Obtain certifications to take your career to the next
          level. Never stop learning and improving.
        </p>
      </div>
    </div>
    <sp-loader v-if="!loaded" />
    <div v-if="loaded" class="container">
      <LearningPathCards :path-limit="3" />
      <div class="text-left mt-5 mb-4">
        <h2 class="font-weight-normal">
          Explore the SolidProfessor Library
        </h2>
      </div>
      <div class="row card-grid mb-4">
        <div v-for="soft in software" v-once :key="soft.id"
             class="col-sm-12 col-md-6 col-lg-4 card-grid-col">
          <router-link :to="{ name: 'software', params: { swslug: soft.slug } }" class="card card-clickable card-software my-3">
            <div class="card-body text-center p-4">
              <div class="mx-auto card-image mb-4">
                <b-img-lazy :src="SPENV.S3_URL + '/images/software-thumbs/sw-logo-' + soft.id + '.png'" alt="Software Image" onerror="this.onerror=null;this.src='/static/images/sp-logo.png'"
                            style="max-width:160px; max-height:110px;" />
              </div>
              <h6 class="card-title font-weight-semibold">
                {{ soft.name }}
              </h6>
              <div class="card-subtitle text-muted row mt-3" align-v="end">
                <div class="col text-right pl-2 pr-2 font-14">
                  <font-awesome-icon :icon="['fas', 'play-circle']" />
                  {{ roundTensCourses(soft.course_count) }}
                </div>
                <div class="col text-left pl-2 pr-2 font-14">
                  <font-awesome-icon :icon="['fas', 'clock']" />
                  {{ nearestHour(soft.duration) }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import computeMetaData from '@/utils/seoTable.js';
import LearningPathCards from '@/components/LearningPaths/LearningPathCards';

export default {
  name: 'Library',
  components: { LearningPathCards },
  extends: BaseComponent,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getMetaData();
      if (!vm.$store.getters['software/getSoftwareListLoaded']) {
        // vm.isLoading = vm.loaded;
        vm.$store.dispatch('software/loadSoftwareList').then(
          () => {
            vm.showFullPageLoading(false);
          })
          .catch(
            (error) => {
              // kick to error state
              console.log(error);
            })
          .then(
            () => {
              vm.$meta().refresh();
            });
      }
    });
  },
  metaInfo() {
    return computeMetaData(
      this.metaData,
      'library',
      this.software,
      this.$route.params
    );
  },
  computed: {
    /**
     * Call the getter from the software store for the entire list, and order by property 'order'
     *
     * @returns array
     */
    software() {
      return this.$store.getters['software/getAll'];
    },
    metaData() {
      return this.$store.getters['software/getMetaData'];
    },
    loaded() {
      return this.$store.getters['software/getSoftwareListLoaded'];
    }
  },

  methods: {
    /**
     * Take an integer representing the number of courses and display
     * in a readable way
     * @param input
     * @param input
     */
    roundTensCourses(input) {
      if (input === 1) {
        return input + ' course';
      } else if (input < 10) {
        return input + ' courses';
      } else {
        return parseInt(input / 10, 10) * 10 + '+ courses';
      }
    },
    getMetaData() {
      this.$store.dispatch('software/loadMetaDataByType', { type: 'library' });
    },
    /**
     * Take an integer representing the number of hours and display
     * in a readable way
     * @returns string
     * @param seconds
     */
    nearestHour(seconds) {
      let hours = parseInt(seconds / 3600);
      if (hours === 0) {
        hours = 1;
      }
      if (hours === 1) {
        return hours + ' hour';
      } else {
        return hours + ' hours';
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
